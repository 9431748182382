// Copyright © 2024 CATTLEytics Inc.

import { useTranslation } from 'react-i18next';

import Page from '../common/components/Page';
import Schedules from './Schedules';

function SchedulesPage(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Page title={t('Schedules')}>
      <Schedules />
    </Page>
  );
}
export default SchedulesPage;
