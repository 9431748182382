// Copyright © 2024 CATTLEytics Inc.

import { Stack } from 'react-bootstrap';

import FormFieldWrapper from '../common/components/FormFieldWrapper';
import { IconCheck } from '../common/utilities';

export const Colors = [
  '#ad1457',
  '#d81b61',
  '#d50000',
  '#e67c73',
  '#f4511e',
  '#ef6c00',
  '#f09300',
  '#f5bf26',
  '#e5c441',
  '#c1ca33',
  '#7bb443',
  '#33b679',
  '#0b8043',
  '#009688',
  '#009be5',
  '#4285f4',
  '#3e51b5',
  '#7986cb',
  '#b39ddb',
  '#9e69af',
  '#8e24aa',
  '#795548',
  '#616161',
  '#a79b8e',
];

type Props = {
  label?: string;
  name: string;
  onChange: (value: string) => void;
  value: string;
};

function ColorPicker({ value, onChange, name, label }: Props): JSX.Element {
  return (
    <FormFieldWrapper label={label}>
      <input hidden name={name} readOnly value={value} />
      <Stack direction="horizontal" gap={1} style={{ flexWrap: 'wrap' }}>
        {Colors.map((color) => (
          <div
            className="rounded-circle d-flex justify-content-center align-items-center"
            key={color}
            onClick={(): void => onChange(color)}
            style={{ backgroundColor: color, width: 25, height: 25 }}
          >
            {value === color && <IconCheck className="text-white" />}
          </div>
        ))}
      </Stack>
    </FormFieldWrapper>
  );
}

export default ColorPicker;
