// Copyright © 2023 CATTLEytics Inc.
import { useEffect, useState } from 'react';
import { Alert, FormGroup, Stack } from 'react-bootstrap';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useTranslation } from 'react-i18next';

import TimeSelect, { getTimeValueInMinutes } from '../common/components/TimeSelect';

type Props = {
  endTime: string;
  onEndTimeChange: (value: Option) => void;
  onStartTimeChange: (value: Option) => void;
  required: boolean;
  startTime: string;
  validateEarly: boolean;
  validated: boolean;
};

function TimeSelectRange({
  endTime,
  onEndTimeChange,
  onStartTimeChange,
  required,
  startTime,
  validateEarly,
  validated,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [showDayOverlap, setShowDayOverlap] = useState(false);

  useEffect(() => {
    const startTimeInMinutes = getTimeValueInMinutes(startTime, t('timeSelect|endOfDay'));
    const endTimeInMinutes = getTimeValueInMinutes(endTime, t('timeSelect|endOfDay'));

    setShowDayOverlap(startTimeInMinutes >= endTimeInMinutes);
  }, [startTime, endTime, setShowDayOverlap, t]);

  return (
    <>
      <FormGroup className="form-group mb-3" controlId="formName">
        <Stack direction="horizontal" gap={4}>
          <TimeSelect
            className="time-dropdown"
            formLabel={t('timeSelect|startTime')}
            id="startTime"
            onChange={(e): void => {
              onStartTimeChange(e[0]);
            }}
            required={required}
            validateEarly={validateEarly}
            validated={validated}
            value={startTime}
          />
          <TimeSelect
            className="time-dropdown"
            formLabel={t('timeSelect|endTime')}
            id="endTime"
            onChange={(e): void => onEndTimeChange(e[0])}
            required={required}
            validateEarly={validateEarly}
            validated={validated}
            value={endTime}
          />
        </Stack>
      </FormGroup>

      {showDayOverlap && (
        <Alert className={'text-center'} variant="info">
          {t('This shift will overlap into the next day')}
        </Alert>
      )}
    </>
  );
}

export default TimeSelectRange;
