// Copyright © 2024 CATTLEytics Inc.

import { addDays, differenceInCalendarDays, getWeek, startOfDay, startOfToday } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DateRangePicker from '../../common/components/DateRangePicker';
import FormFieldWrapper from '../../common/components/FormFieldWrapper';
import SettingsContext from '../../common/store/setings-context';
import { dateFormat } from '../../shared';
import { MultiWeekSelector, SelectedDaysInWeek } from './MultiWeekSelector';

type Props = {
  isLoading?: boolean;
  onChange: (dates: Date[]) => void;
  selectedDate?: Date;
  showLabels?: boolean;
  validated: boolean;
};

export const DailyInputs = ({
  showLabels = false,
  selectedDate,
  onChange,
  validated,
  isLoading,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);

  const [selectedDaysInWeeks, setSelectedDaysInWeeks] = useState<SelectedDaysInWeek[]>([
    { id: 0, days: [0, 1, 2, 3, 4, 5, 6] },
  ]);
  const [startDate, setStartDate] = useState<Date | null>(selectedDate ?? null);
  const [endDate, setEndDate] = useState<Date | null>(selectedDate ?? null);

  const hasDates = useMemo(() => !!(startDate !== null && endDate !== null), [startDate, endDate]);

  const onChangeHandler = useCallback(() => {
    if (startDate && endDate) {
      const diffDates = differenceInCalendarDays(endDate, startDate) + 1;
      const nextDates = new Array(diffDates)
        .fill(undefined)
        .reduce<Date[]>(createReducerMatchingDates(startDate, selectedDaysInWeeks), []);

      // ignore the local tz and assume its entered as the settings.timezone.
      const siteTzDates = nextDates.map((d) => zonedTimeToUtc(d, settings.timeZone));
      onChange(siteTzDates);
    }
  }, [startDate, endDate, selectedDaysInWeeks, onChange, settings.timeZone]);

  const onWeekDaysChangeHandler = useCallback((weekValues: SelectedDaysInWeek[]): void => {
    setSelectedDaysInWeeks(weekValues);
  }, []);
  const onAddWeek = useCallback(() => {
    setSelectedDaysInWeeks((prev) => [...prev, { id: prev.length, days: [0, 1, 2, 3, 4, 5, 6] }]);
  }, []);

  const onRemoveWeek = useCallback((index: number) => {
    setSelectedDaysInWeeks((prev) => prev.filter((_, i) => i !== index));
  }, []);

  useEffect(() => {
    if (hasDates && selectedDaysInWeeks) {
      onChangeHandler();
    }
  }, [hasDates, selectedDaysInWeeks, onChangeHandler]);

  return (
    <div className="d-flex flex-column">
      <FormFieldWrapper
        className="flex-grow-1 daily-inputs"
        invalidFeedback={t('common|fieldRequiredFeedback')}
        label={t('scheduleUserForm|selectDateLabel')}
      >
        <DateRangePicker
          autoComplete="off"
          disabled={isLoading}
          endDate={endDate}
          labels={{
            show: showLabels,
            start: t('scheduleUserForm|rangeStartLabel'),
            end: t('scheduleUserForm|rangeEndLabel'),
          }}
          minDate={startOfToday()}
          onEndDateChange={setEndDate}
          onStartDateChange={setStartDate}
          placeholderText={dateFormat.toUpperCase()}
          required
          showPopperArrow={false}
          startDate={startDate}
          validated={validated}
        />
      </FormFieldWrapper>
      <FormFieldWrapper label={t('scheduleUserForm|weekdayLabel')}>
        <MultiWeekSelector
          onAddWeek={onAddWeek}
          onDaysChangeHandler={onWeekDaysChangeHandler}
          onRemoveWeek={onRemoveWeek}
          selectedDaysInWeeks={selectedDaysInWeeks}
        />
      </FormFieldWrapper>
    </div>
  );
};

/**
 * Given the start date and list of selected days by week, return a list of matching dates to schedule.
 */
function createReducerMatchingDates(
  startDate: Date,
  selectedDaysInWeeks: SelectedDaysInWeek[],
): (prev: Date[], currentValue: unknown, index: number) => Date[] {
  return (prev: Date[], _currentValue: unknown, index: number): Date[] => {
    const newDate = startOfDay(addDays(startDate, index));
    // each week is different, must check the relevant week.
    const startWeek = getWeek(startDate);

    const relativeWeek = getWeek(newDate) - startWeek;
    // relativeWeek will be a 0-based number.
    // modulo operator gives the remainder after division. which can be used determine which weeks item
    // to use to get schedule days for the week
    // eg.
    // If the user picks June 2 to 29, 2024, it would be 4 weeks total.
    // and the user sets a two week rotation, in the first week it would be 0 % 2, which is 0.
    // the second week would be 1%2 = 1
    // the third week would be 2%2 = 0
    // the fourth week would be 3%2 = 1
    const weekIndex = relativeWeek % selectedDaysInWeeks.length;

    if (selectedDaysInWeeks[weekIndex]?.days.includes(newDate.getDay())) {
      return [...prev, newDate];
    }

    return prev;
  };
}
