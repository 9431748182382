// Copyright © 2024 CATTLEytics Inc.

import { addDays, setHours, setMinutes } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { formatDate } from '../common/utilities';
import { Shift, UserSchedule } from '../shared';

const tooltipDateFormat = 'MMM d, p';

export const getShiftDateString = (
  shift: Shift,
  schedules: UserSchedule[],
  timeZone: string,
): string => {
  const { startTimeHour, startTimeMinutes, endTimeHour, endTimeMinutes } = shift;
  const date = utcToZonedTime(schedules[0].date, timeZone);
  const startDate = getShiftDate(date, startTimeHour, startTimeMinutes);
  let endDate = getShiftDate(date, endTimeHour, endTimeMinutes);
  if (startDate > endDate) {
    endDate = addDays(endDate, 1);
    return `${formatDate(startDate, tooltipDateFormat)} - ${formatDate(
      endDate,
      tooltipDateFormat,
    )}`;
  } else {
    return `${formatDate(startDate, tooltipDateFormat)} - ${formatDate(endDate, 'p')}`;
  }
};

export const getShiftDate = (date: Date, hour: number, minutes: number): Date =>
  setHours(setMinutes(date, minutes), hour);
