// Copyright © 2023 CATTLEytics Inc.

import {
  addDays,
  addMonths,
  endOfDay,
  format,
  getDay,
  parse,
  startOfDay,
  startOfWeek,
  subMonths,
} from 'date-fns';
import { enUS } from 'date-fns/locale';
import { utcToZonedTime } from 'date-fns-tz';
import { groupBy } from 'lodash';
import { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import {
  Calendar,
  CalendarProps,
  dateFnsLocalizer,
  Event,
  EventProps,
  SlotInfo,
} from 'react-big-calendar';
import { Alert, OverlayTrigger, OverlayTriggerProps, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import DeleteConfirmModal from '../common/components/DeleteConfirmModal';
import { useDeleteConfirmModal } from '../common/hooks';
import AuthContext from '../common/store/auth-context';
import SettingsContext from '../common/store/setings-context';
import { formatDate, IconCancel, IconWarning, isSiteAdminOrAbove } from '../common/utilities';
import { api } from '../common/utilities/api';
import { ApiResourceV1, HttpMethod, QueryKey, Shift, UserSchedule } from '../shared';
import { getShiftDate, getShiftDateString } from './shiftHelpers';

type ResourceType = { schedules: UserSchedule[]; shift: Shift };

const eventPropGetter: CalendarProps['eventPropGetter'] = (event) => ({
  style: {
    backgroundColor: (event.resource.shift as Shift).color,
  },
  className: 'schedule-event',
});

type TooltipProps = {
  event: Event;
  label?: string;
  tooltip?: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
};

const TooltipComp = ({
  tooltipPlacement,
  event,
  children,
}: React.PropsWithChildren<TooltipProps & Pick<OverlayTriggerProps, 'children'>>): JSX.Element => {
  const settings = useContext(SettingsContext);
  const shift = useMemo(() => event.resource.shift as Shift, [event]);
  const schedules = useMemo(() => event.resource.schedules as UserSchedule[], [event]);
  const dateStr = useMemo(
    () => getShiftDateString(shift, schedules, settings.timezone),
    [shift, schedules, settings.timezone],
  );
  return (
    <OverlayTrigger
      overlay={
        <Popover id="popover-basic" style={{ minWidth: 200 }}>
          <Popover.Header
            as="h3"
            className="text-white text-center"
            style={{ backgroundColor: shift.color }}
          >
            <div>{shift.name}</div>
            <div className="mt-1" style={{ fontSize: '0.75rem' }}>
              {dateStr}
            </div>
          </Popover.Header>
          <Popover.Body>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              {schedules.reduce<ReactNode[]>((prev, { user }, i) => {
                if (user) {
                  const { firstName, lastName, id } = user;
                  prev.push(
                    <li key={`${id}-${i}`} style={{ margin: 0 }}>{`${firstName} ${lastName}`}</li>,
                  );
                }
                return prev;
              }, [])}
            </ul>
          </Popover.Body>
        </Popover>
      }
      placement={tooltipPlacement}
    >
      {children}
    </OverlayTrigger>
  );
};

function EventItem({ event }: EventProps): JSX.Element {
  return (
    <TooltipComp event={event}>
      <div className="h-100">{event.title}</div>
    </TooltipComp>
  );
}

function AgendaEvent({ event }: EventProps): JSX.Element {
  const { t } = useTranslation();
  const schedules = useMemo(() => event.resource?.schedules as UserSchedule[], [event]);
  const queryClient = useQueryClient();
  const [showDeleteUserSchedule, setShowDeleteUserSchedule] = useState<UserSchedule>();
  const shift = useMemo(() => event.resource?.shift as Shift, [event]);

  const {
    deleteConfirmModalOpen,
    deleteConfirmModalErrorMessage,
    openDeleteConfirmModal,
    closeDeleteConfirmModal,
  } = useDeleteConfirmModal();

  const openModal = useCallback(
    (userSchedule: UserSchedule) => {
      setShowDeleteUserSchedule(userSchedule);
      openDeleteConfirmModal();
    },
    [openDeleteConfirmModal, setShowDeleteUserSchedule],
  );

  const onSuccess = useCallback(() => {
    closeDeleteConfirmModal();
    queryClient.invalidateQueries(QueryKey.UserSchedules);
  }, [queryClient, closeDeleteConfirmModal]);

  const { isLoading: deleteIsLoading, mutateAsync: deleteAsync } = useMutation<
    UserSchedule | undefined
  >(
    () => {
      if (showDeleteUserSchedule?.id) {
        return api(
          HttpMethod.Delete,
          `${ApiResourceV1.UserSchedules}/${showDeleteUserSchedule.id}`,
        );
      }
      return Promise.reject();
    },
    {
      onSuccess,
    },
  );

  const onDelete = useCallback(() => deleteAsync(), [deleteAsync]);

  return (
    <div onClick={(e): void => e.stopPropagation()}>
      <div>{shift.name}:</div>
      <ul className="text-body list-group">
        {schedules.reduce<ReactNode[]>((prev, schedule) => {
          if (schedule.user) {
            const { firstName, lastName, id } = schedule.user;

            prev.push(
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                key={id}
                style={{ backgroundColor: 'rgba(0,0,0,0.05)' }}
              >
                <div>{`${firstName} ${lastName}`}</div>
                <IconCancel
                  onClick={(): void => {
                    openModal(schedule);
                  }}
                  style={{ cursor: 'pointer', width: '1.3rem', height: '1.3rem' }}
                />
              </li>,
            );
          }

          return prev;
        }, [])}
      </ul>
      <DeleteConfirmModal
        busy={deleteIsLoading}
        cancelOnClick={(): void => closeDeleteConfirmModal()}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t('Yes, remove this schedule')}
        okOnClick={onDelete}
        title={t('Remove this schedule')}
        value={t('schedule')}
        visible={deleteConfirmModalOpen}
      />
    </div>
  );
}

function AgendaTime(props: any): JSX.Element {
  const label = useMemo(() => {
    if ('event' in props) {
      const { shift, schedules } = props.event.resource as ResourceType;
      const schedule = schedules[0];

      if (schedule) {
        const startDate = getShiftDate(
          new Date(schedule.date),
          shift.startTimeHour,
          shift.startTimeMinutes,
        );
        const endDate = getShiftDate(
          new Date(schedule.date),
          shift.endTimeHour,
          shift.endTimeMinutes,
        );

        if (startDate > endDate) {
          return `${formatDate(startDate, 'p')} – ${formatDate(addDays(endDate, 1), 'MMM d, p')}`;
        }
      }
    }

    if ('label' in props) {
      return props.label as string;
    }

    return '';
  }, [props]);

  return <div style={{ textTransform: 'none' }}>{label}</div>;
}

const components: CalendarProps['components'] = {
  event: EventItem,
  agenda: {
    event: AgendaEvent,
    time: AgendaTime,
  },
};

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

type Props = {
  onCreateNewSchedule?: (date: Date) => void;
  onSelectedSchedules?: (schedules: UserSchedule[]) => void;
  shiftIds?: number[];
  userIds?: number[];
};

function SchedulesCalendar({
  onSelectedSchedules,
  onCreateNewSchedule,
  userIds = [],
  shiftIds = [],
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [date, setDate] = useState<Date>(new Date());
  const dateStart = useMemo(() => subMonths(date, 1), [date]);
  const dateEnd = useMemo(() => addMonths(date, 1), [date]);
  const auth = useContext(AuthContext);
  const isEditable = useMemo(() => isSiteAdminOrAbove(auth), [auth]);
  const settings = useContext(SettingsContext);

  const { data } = useQuery<UserSchedule[]>(
    [QueryKey.UserSchedules, dateStart, dateEnd, userIds, shiftIds],
    () => {
      const params =
        userIds.length > 0 || shiftIds.length > 0
          ? {
              dateEnd: dateEnd.toISOString(),
              dateStart: dateStart.toISOString(),
              userIds: userIds.map((id) => String(id)),
              shiftIds: shiftIds.map((id) => String(id)),
            }
          : {
              dateEnd: dateEnd.toISOString(),
              dateStart: dateStart.toISOString(),
            };
      return api(HttpMethod.Get, ApiResourceV1.UserSchedules, {
        params: params as unknown as Record<string, string>,
      });
    },
    {},
  );

  const events = useMemo(() => {
    if (!data?.length) return [];

    const groupDates = groupBy(data, ({ date, shiftId }) => `${date}-${shiftId}`);

    return Object.values(groupDates).reduce<Event[]>((prev, list) => {
      const shift = list[0].shift;
      if (shift) {
        const date = utcToZonedTime(list[0].date, settings.timeZone);
        // const date = new Date(list[0].date);
        const { endTimeHour, endTimeMinutes, startTimeHour, startTimeMinutes } = shift;
        const users = list
          .reduce<string[]>((prev, { user }) => (user ? [...prev, user.firstName] : prev), [])
          .join(', ');

        const startDate = getShiftDate(date, startTimeHour, startTimeMinutes);
        let endDate = getShiftDate(date, endTimeHour, endTimeMinutes);

        if (startDate > endDate) {
          endDate = endOfDay(startDate);
        }

        return [
          ...prev,
          {
            title: users,
            start: startDate,
            end: endDate,
            resource: { shift, schedules: list } as ResourceType,
          },
        ];
      }

      return prev;
    }, []);
  }, [data, settings.timeZone]);

  const onNavigate = useCallback((date: Date) => {
    setDate(date);
  }, []);

  const onSelectSlot = useCallback(
    ({ start }: SlotInfo): void => {
      const sd = startOfDay(start);
      const now = startOfDay(new Date());
      if (sd >= now) {
        onCreateNewSchedule?.(start);
      }
    },
    [onCreateNewSchedule],
  );

  const onEventSelected = useCallback(
    (evt: Event): void => {
      onSelectedSchedules?.(evt.resource.schedules);
    },
    [onSelectedSchedules],
  );

  const getDrilldownView = useCallback((targetDate, currentViewName, configuredViewNames) => {
    if (currentViewName === 'month' && configuredViewNames.includes('week')) return 'week';

    return null;
  }, []);

  const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const showTzWarning = userTz !== settings.timeZone;

  return (
    <section className="mt-3">
      {showTzWarning && (
        <Alert variant="danger">
          <IconWarning className="me-2" />
          <strong>{t('scheduleCalendar|warningLabel')}</strong>:{' '}
          {t('scheduleCalendar|warningTzLabel', { userTz: userTz, serverTz: settings.timeZone })}
        </Alert>
      )}
      <Calendar
        className="scheduleCalender"
        components={components}
        date={date}
        dayLayoutAlgorithm="no-overlap"
        // dayPropGetter={dayPropGetter}
        endAccessor="end"
        eventPropGetter={eventPropGetter}
        events={events}
        getDrilldownView={getDrilldownView}
        localizer={localizer}
        onNavigate={onNavigate}
        onSelectEvent={onEventSelected}
        onSelectSlot={onSelectSlot}
        selectable={isEditable}
        startAccessor="start"
        style={{ minHeight: 805 }} // Display four rows on five-week calendars
        views={{ day: false, month: true, week: true, agenda: true }}
      />
    </section>
  );
}

export default SchedulesCalendar;
